<template>
  <div class="user-watch-rules__page">
    <div
      v-if="fetching"
      class="user-watch-rules--loading">
      <v-progress-circular
        indeterminate
        size="100"
        color="secondary"/>
    </div>
    <div
      v-else-if="watchRules.length"
      class="user-watch-rules">
      <h3
        class="page-title"
        v-text="$t('page.title')"/>
      <p
        class="page-description"
        v-text="$t('page.description')"/>
      <v-data-table
        :items="filteredItems"
        :headers="$static.tableHeaders"
        disable-pagination
        hide-default-footer>
        <template #top>
          <v-toolbar
            height="96"
            flat>
            <div>
              <CySearchBox
                v-model.trim="searchTerm"
                aria-label="Search items"
                :placeholder="$t('searchPlaceholder')"
                append-icon="search"
                clearable
                class="search-field"/>
              <h4
                class="user-watch-rules__count"
                v-html="$sanitizeHtml(itemCountText)"/>
            </div>
            <v-spacer/>
            <CyButton
              theme="secondary"
              icon="add"
              @click="createWatchRule">
              {{ $t('createWatchRule') }}
            </CyButton>
          </v-toolbar>
        </template>
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <CyAvatar
              :item="{
                icon: item.project_canonical ? 'commit' : 'podcasts',
                color: item.project_canonical ? 'prod' : 'staging',
              }"
              class="mr-2"
              sm/>
            <strong>
              {{ item.name }}
            </strong>
          </div>
        </template>
        <template #[`item.muted`]="{ item }">
          <CyTag
            v-if="item.muted"
            icon-before="mute">
            {{ $t('muted') }}
          </CyTag>
        </template>
        <template #[`item.project_canonical`]="{ item }">
          <span
            v-if="item.project_canonical"
            class="cy-link"
            @click="goToProject(item.project_canonical)">
            <v-icon class="mr-1">
              folder_open
            </v-icon>
            {{ item.project_canonical }}
          </span>
        </template>
        <template #[`item.organization_canonical`]="{ item }">
          <div class="d-flex align-center">
            <CyAvatar
              :item="getOrgByCanonical(item.organization_canonical)"
              class="mr-2"
              sm/>
            {{ getOrgByCanonical(item.organization_canonical).name }}
          </div>
        </template>
      </v-data-table>
    </div>
    <div
      v-else
      class="user-watch-rules--empty">
      <v-icon>
        visibility
      </v-icon>
      <div
        class="empty-watch-rules__title"
        v-text="$t('emptyWatchRules.title')"/>
      <div
        class="empty-watch-rules__text"
        v-text="$t('emptyWatchRules.text')"/>
      <CyButton
        theme="secondary"
        icon="add"
        @click="createWatchRule">
        {{ $t('createWatchRule') }}
      </CyButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CySearchBox from '@/components/search-box.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageUserWatchRules',
  components: {
    CySearchBox,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.userWatchRules'), [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  data: () => ({
    searchTerm: '',
    fetching: true,
  }),
  computed: {
    ...mapState('notifications', [
      'watchRules',
    ]),
    ...mapState({
      organizations: (state) => state.organizations,
    }),
    $static () {
      return {
        tableHeaders: [
          {
            text: this.$t('headers.name'),
            value: 'name',
          },
          {
            text: this.$t('headers.status'),
            value: 'muted',
          },
          {
            text: this.$t('headers.project'),
            value: 'project_canonical',
          },
          {
            text: this.$t('headers.organization'),
            value: 'organization_canonical',
          },
        ],
      }
    },
    itemCountText () {
      const count = this.filteredItems.length
      const text = this.$tc('inventory.nItems', count, { n: count })
      return this.$sanitizeHtml(text)
    },
    filteredItems () {
      if (!this.searchTerm) return this.watchRules

      const search = this.searchTerm.toLowerCase()

      return this.watchRules.filter((item) => {
        return item.name.toLowerCase().includes(search)
      })
    },
  },
  created () {
    this.fetchWatchRules()
  },
  methods: {
    ...mapActions('notifications', [
      'GET_WATCH_RULES',
    ]),
    getOrgByCanonical (canonical) {
      return _.find(this.organizations, { canonical })
    },
    goToProject (projectCanonical) {
      const { orgCanonical } = this
      this.$router.push({
        name: 'project',
        params: {
          orgCanonical,
          projectCanonical,
        },
      })
    },
    async fetchWatchRules () {
      this.fetching = true
      await this.GET_WATCH_RULES()
      this.fetching = false
    },
    createWatchRule () {
      this.$router.push({ name: 'newUserWatchRule' })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.userWatchRules',
        createWatchRule: 'Create watch rule',
        emptyWatchRules: {
          title: `You're not watching anything yet`,
          text: 'Watch rules help you stay in the loop by sending notifications whenever changes are made to a project or a specific event occurs.',
        },
        headers: {
          name: 'Rule name',
          status: '@:forms.status',
          project: '@:Project',
          organization: '@:Organization',
        },
        page: {
          title: 'Watching',
          description: 'Watch rules help you stay in the loop by sending notifications whenever changes are made to a project or a specific event occurs.',
        },
        searchPlaceholder: 'Search items...',
        muted: 'Muted',
      },
      es: {
        title: '@:routes.userWatchRules',
        createWatchRule: 'Crear regla de monitoreo',
        emptyWatchRules: {
          title: `Aún no estás siguiendo nada`,
          text: 'Las reglas de seguimiento te ayudan a mantenerte informado enviándote notificaciones cada vez que se realizan cambios en un proyecto o se produce un evento específico.',
        },
        headers: {
          name: 'Nombre de la regla',
          status: '@:forms.status',
          project: '@:Project',
          organization: '@:Organization',
        },
        page: {
          title: 'Siguiendo',
          description: 'Las reglas de seguimiento te ayudan a mantenerte informado enviándote notificaciones cada vez que se realizan cambios en un proyecto o se produce un evento específico.',
        },
        searchPlaceholder: 'Buscar elementos...',
        muted: 'Silenciado',
      },
      fr: {
        title: '@:routes.userWatchRules',
        createWatchRule: 'Créer une règle de notification',
        emptyWatchRules: {
          title: `Vous n'avez encore aucune règle de notification`,
          text: `Les règles de notifications vous aident à rester informé en vous envoyant des notifications chaque fois que des modifications sont apportées à un projet ou qu'un événement spécifique se produit.`,
        },
        headers: {
          name: 'Nom de la règle',
          status: '@:forms.status',
          project: '@:Project',
          organization: '@:Organization',
        },
        page: {
          title: 'Suivi de notifications',
          description: `Les règles de notifications vous aident à rester informé en vous envoyant des notifications chaque fois que des modifications sont apportées à un projet ou qu'un événement spécifique se produit.`,
        },
        searchPlaceholder: 'Rechercher des éléments...',
        muted: 'Désactivée',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.user-watch-rules {
  width: 100%;

  ::v-deep {
    .v-data-table,
    .v-toolbar {
      background-color: transparent;
      color: cy-get-color("primary");
    }

    .v-data-table {
      .v-data-table-header th {
        color: cy-get-color("primary");
        font-size: $font-size-default;
        font-weight: $font-weight-bold;
      }
    }

    .v-toolbar__content {
      align-items: flex-start;
      padding: 4px 0;
    }
  }

  &__page {
    display: flex;
    justify-content: center;
  }

  &__count {
    margin-bottom: 16px;
    font-weight: $font-weight-default;
  }

  &--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    padding: 32px 0;
    text-align: center;

    .v-icon {
      margin-bottom: 24px;
      color: cy-get-color("primary", "light-3");
    }

    .empty-watch-rules {
      &__title {
        margin-bottom: 8px;
        color: cy-get-color("primary");
        font-size: $font-size-lg;
        font-weight: 700;
      }

      &__text {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
